import ProfileBox from "../components/profileBox";
import { useHistory } from 'react-router-dom';

function DashboardStart() {
  const history = useHistory();
  const goToWarning = async () => {
    history.push('/dashboard/warning');
  };

  return (
    <div>
      <h3><strong>Je moet nu enkel nog je bank linken en je bent al helemaal klaar! </strong> </h3>
      <br/>
      <ProfileBox />
      <button className="buttonOrange" onClick={goToWarning}>Bank linken</button>
    </div>  
  );
}

export default DashboardStart;