import { useRouteMatch } from "react-router-dom";
import Improvement1 from "./Improvement1";
import Improvement2 from "./Improvement2";

function ImprovementsWrapper() {
  const match = useRouteMatch("/improvements/:id");

  const renderImprovement = () => {
    if (!match) return null;

    const improvementId = parseInt(match.params.id, 10);

    switch (improvementId) {
      case 1:
        return <Improvement1 />;
      case 2:
        return <Improvement2 />;
      // ... add cases for all the other improvements
      default:
        return <div>Woeps! Dit klopt niet helemaal, probeer het nog eens.</div>;
    }
  };

  return <>{renderImprovement()}</>;
}

export default ImprovementsWrapper;