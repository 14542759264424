import { useHistory } from "react-router-dom";


const ImprovementsOverview = () => {
  const history = useHistory();


  const handleTip = (num) => {
    history.push("/improvements/"+num);
  };

  return (
    <div>
      <h2>Bekijk je Tip(s) opnieuw!</h2>
        <button
          className="buttonOrange"
          onClick={() => handleTip(1)}
        >
          Tip 1
        </button>
        <button
          className="buttonGrey"
        >
          Tip 2
        </button>
        <button
          className="buttonGrey"
        >
          Tip 3
        </button>
        <button
          className="buttonGrey"
        >
          Tip 4
        </button>
        <button
          className="buttonGrey"
        >
          Tip 5
        </button>
        <button
          className="buttonGrey"
        >
          ...
        </button>
      <br />
    </div>
  );
};

export default ImprovementsOverview;
