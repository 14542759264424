import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useUserContext } from "../../context/userContext";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import your Firestore instance
import { useLocation } from "react-router-dom";


const FeedbackPopup = () => {
  const [feedback, setFeedback] = useState("");
  const { user } = useUserContext();
  const history = useHistory();
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    return () => {
      setVisible(true);
    };
  }, []);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = (feedback) => {
    onSubmit(feedback);
    setVisible(false);
  };


  const location = useLocation();
  const improvementNumber = location.pathname.split("/").pop();

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const onSubmit = async (event) => {
    console.log(feedback);
    if (user) {
      const ratingsRef = collection(db, `improvement${improvementNumber}`, user.uid, "ratings");
      await addDoc(ratingsRef, {
        rating: 0,
        feedback: feedback,
        timestamp: new Date(),
      });
    }
    alert("Bedankt voor je feedback!");
    onClose();
  };

  const onClose = () => {
    history.push("/dashboard");
  };

  return (
    visible && (<div className="feedbackPopup">
      <div className="infoPopupBackdrop">
        <div className="infoPopupContent">
          <h2>Geef ons meer informatie<br /> zo leren we bij:</h2>
          <textarea
            id="feedback"
            className="feedbackTextarea"
            placeholder="Typ hier je feedback..."
            value={feedback}
            onChange={handleFeedbackChange}
          ></textarea>
          <br />
          <button className="buttonOrange" onClick={handleSubmit}>
            Verzenden
          </button>
          <button className="buttonGrey" onClick={handleCancel}>
            Annuleren
          </button>
        </div>
      </div>
    </div>)
  );
};

export default FeedbackPopup;
