import { useState, useEffect } from "react";
import { db } from "../../firebase"; // Import your Firestore instance
import { onSnapshot, doc  } from "firebase/firestore";
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import Twemoji from 'react-twemoji';
import { useUserContext } from "../../context/userContext";

import FeedbackPopup from "./FeedbackPopup";
import StartScreen from "./StartScreen";
import RatingScreen from "./RatingScreen";
import SwiperNextButton from "./SwiperNextButton";

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/scrollbar/scrollbar.min.css'

function Improvement1() {
  const { user } = useUserContext();

  const [promiseMade, setPromiseMade] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [data, setData] = useState([]);

  const handleInfoClick = () => {
    setShowInfoPopup(true);
  };  

  const closeInfoPopup = () => {
    setShowInfoPopup(false);
  };

  
  useEffect(() => {
    if (user) {
      const improvement1DocRef = doc(db, "improvement1", user.uid);
      const unsubscribe = onSnapshot(improvement1DocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          console.log(docSnapshot.data());
          setData(docSnapshot.data());
        } else {
          console.error("No document found with the user's UID");
        }
      });
  
      return () => unsubscribe();
    }
  }, [user]);

  const InfoPopup = () => {
    return (
      <div className="infoPopupBackdrop">
        <div className="infoPopupContent">
          <h2>Waarom een bepaald percentage van je salaris sparen?</h2>
          <p>
            Het sparen van een bepaald percentage van je salaris is een geweldige manier om financiële stabiliteit en zekerheid op te bouwen. Door elke maand een deel van je inkomen opzij te zetten, bouw je een buffer op die je kan helpen bij onverwachte kosten of noodgevallen. Deze buffer kan ook een belangrijk hulpmiddel zijn bij het bereiken van je financiële doelen, zoals het kopen van een huis of het financieren van je pensioen. <sup>1</sup>
          </p>
          <p>
            Experts raden aan om 10% tot 20% van je salaris te sparen, afhankelijk van je financiële situatie en doelen. Door een vast percentage te sparen, creëer je een gewoonte die je helpt je spaardoelen op lange termijn te bereiken. Bovendien zorgt het ervoor dat je leert om binnen je budget te leven, wat kan bijdragen aan het vermijden van schulden en het opbouwen van een gezonde financiële basis. <sup>2</sup>
          </p>
          <p>
            Als je moeite hebt met sparen, kan het nuttig zijn om automatisch een deel van je salaris naar een spaarrekening over te schrijven. Dit zorgt ervoor dat je spaardoelen worden bereikt zonder dat je er elke maand bewust aan hoeft te denken. Door automatisch te sparen, bouw je gestaag je spaargeld op en werk je aan een financieel stabiele toekomst. <sup>3</sup>
          </p>
          <p>
            Kortom, het sparen van een bepaald percentage van je salaris is een belangrijk onderdeel van je financiële welzijn. Het helpt je om voorbereid te zijn op onverwachte kosten, schulden te vermijden en je doelen te bereiken. Neem de tijd om je spaargewoonten te evalueren en te bepalen welk percentage voor jou het beste werkt.
          </p>
          <p>
            Bronnen:<br />
            <sup>1</sup> <a href="https://www.nibud.nl/consumenten/themas/sparen/" target="_blank" rel="noopener noreferrer">Nibud - Sparen</a><br />
            <sup>2</sup> <a href="https://www.consumentenbond.nl/sparen-en-beleggen/hoeveel-sparen" target="_blank" rel="noopener noreferrer">Consumentenbond - Hoeveel sparen?</a><br />
            <sup>3</sup> <a href="https://www.rabobank.nl/particulieren/geld-uitgeven/sparen/automatisch-sparen/" target="_blank" rel="noopener noreferrer">Rabobank - Automatisch sparen</a>
          </p>
          <button className="buttonOrange" onClick={closeInfoPopup} >
            Ik snap het!
          </button>
        </div>
      </div>
    );
  };

  const renderImprovementContent = () => {
    return (
    <div className="tipsWrapper">
      <Swiper
        className="Swiper"
        modules={[Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        allowTouchMove = {false}
        autoHeight = {true}
        centeredSlides = {true}
        noSwiping = {true}
      >
        <SwiperSlide className="SwiperSlide">
          <Twemoji options={{ className: 'emoji', folder: "svg", ext: ".svg" }}>
            <span>💸</span>
          </Twemoji>
            <h2>Je loon is:</h2>
            <h1>€{data.salary}</h1>
            <p>{data.reason}</p>
            <br/>
            <br/>
            <SwiperNextButton content="Ga verder"/>
            <br/> 
            <button className="buttonGrey" onClick={() => setShowFeedbackPopup(true)}>Dit klopt niet</button>
        </SwiperSlide>
        <SwiperSlide className="SwiperSlide">
          <Twemoji options={{ className: 'emoji', folder: "svg", ext: ".svg" }}>
            <span>💰</span>
          </Twemoji>
            <h2>Je spaart dus best:</h2>
            <h1>€{data.salary * 0.1}</h1>
            <p>Dat is 10% van je salaris, en je doet dit best binnen de 5 dagen na het ontvangen van je salaris.</p>
            <br/>
            <p onClick={handleInfoClick}><u>Meer info</u></p>
            <br/>
            <br/>
            <SwiperNextButton content="Ga verder"/>
            <br/> 
            <button className="buttonGrey" onClick={() => setShowFeedbackPopup(true)}>Dit doe ik al</button>
        </SwiperSlide>
        <SwiperSlide className="SwiperSlide">
          <Twemoji options={{ className: 'emoji', folder: "svg", ext: ".svg" }}>
            <span>🤖</span>
          </Twemoji>
            <h1>Stel het nu in!</h1>
            <h2>En krijg 1 maand gratis premium zodra we launchen.</h2>
            <br/>
            <ol className="tipInstructies">
              <li>Open je bank app</li>
              <li>Stel een automatische overschrijving in naar een <strong>spaarrekening</strong></li>
              <li>Gebruik <strong className="orange">"KASZA"</strong> in je beschrijving</li>
              <li>Stel het bedrag in op <strong>€{data.salary * 0.1}</strong></li>
              <li>Stel de frequentie in op <strong>maandelijks</strong></li>
              <li>Stel de startdatum in op <strong>max 5 dagen na je salaris</strong></li>
              <li>Bevestig</li>
              <li>Je bent klaar! 🚀</li>
            </ol>
            <br/>
            <p><i><small>*Binnenkort kan dit volledig automatisch maar nu moet je het nog even zelf doen</small></i></p> <br/> 
            <SwiperNextButton content="Klaar"/>
            <br/> 
            <button className="buttonGrey" onClick={() => setShowFeedbackPopup(true)}>Nu niet</button>
        </SwiperSlide>
        <SwiperSlide className="SwiperSlide">
          <RatingScreen />
        </SwiperSlide>
      </Swiper>
    </div>
    );
  };

      return (
        <div>
          {showInfoPopup && <InfoPopup />}
          {showFeedbackPopup && <FeedbackPopup />}
          {promiseMade ? (
              renderImprovementContent()
          ) : (
            <StartScreen onPromiseMade={() => setPromiseMade(true)} />
          )}
        </div>
      );
    }

    export default Improvement1;
