import { useHistory } from 'react-router-dom';

function DashboardWarning({functionStart}) {

  const history = useHistory();
  const goToPicker = async () => {
    history.push('/dashboard/picker');
  };

  return (
    <div>
      <div className="profileBox">
        <h1> <strong>WAARSCHUWING</strong>  </h1>
        <p>
        Na je bank selectie ga je via een aantal pop-up schermen connecteren met je bank. 
        <br/>
        <br/>
        Jammer genoeg hebben wij niet onder controle hoe vlot dit gaat. Dit hangt af <strong>van de bank zelf</strong>.
        <br/>
        <br/>
        Onze excuses hier voor! Als het toch niet lukt, stuur dan meteen een mailtje naar <a href="mailto:hoi@kasza.be">hoi@kasza.be</a> of gebruik de <strong>chat</strong> functie!
        </p>    
      </div>
        <button className="buttonOrange" onClick={goToPicker}>✓</button>
    </div>  
  );
}

export default DashboardWarning;