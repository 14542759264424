import Loading from "./components/Loading";
import Tips from "./components/tips";
import Auth from "./components/auth";
import Linked from "./components/linked";
import PricingPage from "./components/stripeCheckout";
import { useUserContext } from "./context/userContext";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import BackButton from "./components/BackButton";
import DashboardStart from "./components/DashboardStart";
import DashboardWarning from "./components/DashboardWarning";
import DashboardPicker from "./components/DashboardPicker";
import ImprovementsWrapper from "./components/improvements/ImprovementsWrapper";
import ImprovementsOverview from "./components/improvements/ImprovementsOverview";

function App() {
  const { user, error } = useUserContext();
    const PrivateRoute = ({ component: Component, ...rest }) => {
      const { user, loading } = useUserContext();
      return (
        <Route
          {...rest}
          render={(props) =>
            loading ? (
              <Loading />
            ) : user ? (
              <Component {...props} />
            ) : (
              <Auth />
            )
          }
        />
      );
    };

  return (
    <div className="App">
      <div className="container">
          
        <Router>
          <BackButton/>
          <img className="logo" alt="Kasza logo" src="../logo.png" />
          { error ? (<div className="error">{error}</div>) : ""}
          <Switch>
            <Route path="/login" exact>
              {user ? <Redirect to="/dashboard" /> : <Auth />}
            </Route>
            <PrivateRoute path="/dashboard" component={DashboardStart} exact/>
            <PrivateRoute path="/dashboard/warning" component={DashboardWarning} />
            <PrivateRoute path="/dashboard/picker" component={DashboardPicker} />
            <PrivateRoute path="/linked" component={Linked} />
            <PrivateRoute path="/premium" component={PricingPage} />
            <PrivateRoute path="/tips" component={Tips} />
            <PrivateRoute path="/improvements" component={ImprovementsOverview} exact/>
            <PrivateRoute path="/improvements/:id" component={ImprovementsWrapper} />
            <PrivateRoute path="/" exact>
              {user ? <Redirect to="/dashboard" /> : <Tips />}
            </PrivateRoute>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;