import React from "react";
import { db } from "../firebase";
import ProfileBox from "../components/profileBox";
import { doc, updateDoc, collection, query, where, getDocs } from "firebase/firestore";
import { useUserContext } from "../context/userContext";
import { useHistory } from 'react-router-dom';

const Linked = () => {


  const history = useHistory();
  const { user } = useUserContext();
  console.log(user);
  const [textStep, setTextStep] = React.useState(0);

  // Get the URL parameters 
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // Get the "reqId" parameter value
  const reqId = urlParams.get("reqId");
  const error = urlParams.get("error");
  React.useEffect(() => {
    if (reqId && !error) {
      const requisitionsRef = collection(db, "requisitions");
      console.log(reqId);
      console.log(user.uid);
      const reqQuery = query(requisitionsRef, where("agreement", "==", reqId), where("uid", "==", user.uid));
      getDocs(reqQuery)
        .then((querySnapshot) => {
          console.log(querySnapshot);
          if (querySnapshot.docs.length > 0) {
            const reqDocRef = doc(requisitionsRef, querySnapshot.docs[0].id);
            console.log(reqDocRef);
            updateDoc(reqDocRef, { link_state: "active" });
          }
        })
        .catch((error) => {
          console.log("Error fetching to document:", error);
          setTextStep(3);
        });
    } else {
      setTextStep(3);
    }
  }, [reqId, error, user.uid]);  

  function nextStep() {
    if (textStep >= 2) {
      setTextStep(0);
    } else {
      setTextStep(textStep+1);
    }
  }

  function back() {
    history.push('/dashboard');
  }

  function premium() {
    history.push('/premium');
  }

  return (
    <div>
        {error ? error : ""}
        <div className="backButton" onClick={back}>←</div>

        {textStep === 0 ? <h3> <strong>Dat was het dan ✓ </strong>  </h3> : ""}
        {textStep === 1 ? <h3> <strong>Er is echt niets meer te doen ... </strong>  </h3> : ""}
        {textStep === 2 ? <h3> <strong>Je mag ook gerust hier blijven ... </strong>  </h3> : ""}
        {textStep === 3 ? <h3> <strong>Er ging iets mis ... </strong>  </h3> : ""}
      
        {textStep === 0 ?<p className="linkedP">Het zit er al helemaal op, je zal nu <strong>iedere week</strong> een notificatie krijgen om je gepersonaliseerde tip te bekijken! Tot snel!</p> : ""}
        {textStep === 1 ?<p className="linkedP">Je mag de app met een gerust hart sluiten </p> : ""}
        {textStep === 2 ?<p className="linkedP">Ik heb tijd!</p> : ""}
        {textStep === 3 ?<p className="linkedP">Contacteer ons even via de chat of via hoi@kasza.be aub!</p> : ""}
      
      <br/>
      
      <ProfileBox />

      <div className="doubleButton">
        <button className="buttonOrange" onClick={premium}>Premium</button>
        <button className="buttonGrey" onClick={nextStep}>Verder</button>
      </div>
    </div>  
  );
};

export default Linked;
