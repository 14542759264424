import { useState } from "react";
import Badge from "../Badge";

const StartScreen = ({ onPromiseMade }) => {
  const [buttonHoldTimer, setButtonHoldTimer] = useState(null);
  const [buttonHeld, setButtonHeld] = useState(false);

  const handleButtonPress = () => {
    setButtonHeld(true);
    const timer = setTimeout(() => {
      onPromiseMade();
    }, 3000);
    setButtonHoldTimer(timer);
  };

  const handleButtonRelease = () => {
    setButtonHeld(false);
    clearTimeout(buttonHoldTimer);
    setButtonHoldTimer(null);
  };

  return (
    <div>
      <br />
      <button
        className={buttonHeld ? "buttonBigCircle pulsating" : "buttonBigCircle"}
        onMouseDown={handleButtonPress}
        onMouseUp={handleButtonRelease}
        onTouchStart={handleButtonPress}
        onTouchEnd={handleButtonRelease}
      >
        <span>Tip 1</span>
        <br/>
        <Badge text="Beginner 💪" />
        <br/>
      </button>
      <h2>
      (72 seconden om <br/>hem toe te passen)
      </h2>
    </div>
  );
};

export default StartScreen;
