import * as React from 'react';
import { useUserContext } from "../context/userContext";
import { useHistory } from 'react-router-dom';

function PricingPage() {
  const history = useHistory();
  const { user } = useUserContext();
  console.log(user.uid);
  function back(){
    history.push("/linked");
  }
  // Paste the stripe-pricing-table snippet in your React component
  return (
    /* // PRODUCTION
    <stripe-pricing-table
      pricing-table-id="prctbl_1MqHYqHioG6jktwlsHxoyq3C"
      publishable-key="pk_live_51MqH5nHioG6jktwlD04vZa8PWmnqnJc76zIvzB1MhVUCtp3GFpyZvRkAC2WjXkrN1bhg7KqyY1IJ7igeYxTw7Qai006Vpa0fJr"
    >
    </stripe-pricing-table>
    */
    <div>
      <div className="backButton" onClick={back}>←</div>
      <stripe-pricing-table
        pricing-table-id="prctbl_1MqHAZHioG6jktwlVQXIdKvt"
        publishable-key="pk_test_51MqH5nHioG6jktwlGpnUsiffFerwgbMJr2fhRC4RC4plqjEivXjAmhkCOmpai9Kges1A6DwNYzBoXCViNIIm7Wb300mTnvUvfa"
        client-reference-id={user.uid}
      >
      </stripe-pricing-table>
    </div>
    
  );
}

export default PricingPage;