import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useUserContext } from "../../context/userContext";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import your Firestore instance
import { useLocation } from "react-router-dom";
import Twemoji from 'react-twemoji';

const RatingScreen = () => {
  const { user } = useUserContext();
  const history = useHistory();

  const location = useLocation();
  const improvementNumber = location.pathname.split("/").pop();

  const [rating, setRating] = useState(0);

  const handleFinish = () => {
    history.push("/dashboard");
  };

  const handleRating = async (rating) => {
    rating = rating+1
    if (user) {
      const ratingsRef = collection(
        db,
        `improvement${improvementNumber}`,
        user.uid,
        "ratings"
      );
      await addDoc(ratingsRef, {
        rating,
        timestamp: new Date(),
      });
    }
    handleFinish();
  };

  const handleStarClick = (starIndex) => {
    setRating(starIndex);
  };

  return (
    <div className="SwiperSlide">
    <Twemoji options={{ className: 'emoji', folder: "svg", ext: ".svg" }}>
      <span>⭐</span>
    </Twemoji>
      <h1>Wat vond je van deze tip?</h1>
      <br/>
      <br/>
      <div className="stars">
        {[...Array(5)].map((_, i) => (
          <span
            key={i}
            className={`star${i <= rating ? " gold" : ""}`}
            onClick={() => handleStarClick(i)}
          >
            ★
          </span>
        ))}
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <button className="buttonOrange" onClick={() => handleRating(rating)}>
        Stem
      </button>
      <br />
    </div>
  );
};

export default RatingScreen;
